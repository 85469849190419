import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'



//��ȡ���������µ���������
export const orderProList = (orderId, brandBusinessId, vendorSectionId, isPO) => {
    return request({
        url: pvhApiBase + "Tracing/ShowOrderSectionProcess",
        method:"get",
        params: {
            "orderId": orderId,
            "brandBusinessId": brandBusinessId,
            "vendorSectionId": vendorSectionId,
            "isPO": isPO
        }
    })
}

//��ȡ���������µ������ֶ�����
export const orderFieldList = (orderId, processId, subjectId, brandBusinessId, loadNew) => {
    return request({
        url: pvhApiBase + "Tracing/ShowOrderProcessField",
        method: "get",
        params: {
            "orderId": orderId,
            "processId": processId,
            "subjectId": subjectId,
            "brandBusinessId": brandBusinessId,
            "loadNew": loadNew
        }
    })
}

//��ȡ���������µ������ļ�����
export const orderFileList = (orderId, processId, subjectId, brandBusinessId, loadNew) => {
    return request({
        url: pvhApiBase + "Tracing/ShowOrderProcessFile",
        method: "get",
        params: {
            "orderId": orderId,
            "processId": processId,
            "subjectId": subjectId,
            "brandBusinessId": brandBusinessId,
            "loadNew": loadNew
        }
    })
}


//�����б���ʾ
export const orderSubject = (orderId, processId, brandBusinessId, loadNew ,page, pageSize, ) => {
    return request({
        url: pvhApiBase + "Tracing/ShowOrderSubject",
        method: "get",
        params: {
            "orderId": orderId,
            "processId": processId,
            "brandBusinessId": brandBusinessId,
            "loadNew": loadNew,
            "page": page,
            "pageSize": pageSize
        }

    })
}

//�ϴ��ļ�
export const uploadFile = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostUploadFile",
        method: "post",
        data: {
            ...params
        }
    })
}

//����
export const addSubject = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostSubject",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const updateSubject = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutSubject",
        method: "put",
        data: {
            ...params
        }
    })
}

//����
export const addFieldValue = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostFieldValue",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editFieldValue = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutFieldValue",
        method: "put",
        data: {
            ...params
        }
    })
}




//ɾ��
export const delfile = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteFilePathAndFile",
        method: "delete",
        params: {
            id
        }
    })
}


//ɾ��
export const delSubjectInfo = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSubject",
        method: "delete",
        params: {
            id
        }
    })
}

//ɾ��
export const delFieldInfo = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteFieldValue",
        method: "delete",
        params: {
            id
        }
    })
}